
import { ExamQuestion } from "@/models/exam-question.model";
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["answer"],
  props: {
    question: {
      type: Object as () => ExamQuestion,
      required: true,
    },
  },
});
