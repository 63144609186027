
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { SyllabusModel } from "../models/syllabus.model";
import { loadedSyllabus } from "../state/syllabus.state";
import { Preset, PRESET } from "../state/preset.state";

export default defineComponent({
  setup() {
    const syllabus = ref("");
    const router = useRouter();

    function loadPreset(p: Preset) {
      syllabus.value = p.content;
    }

    function study() {
      const lines = syllabus.value.split("\n");

      const result: SyllabusModel = {
        questions: [],
        answers: [],
      };

      let index = -1;
      for (let line of lines) {
        if (line.startsWith("## ")) {
          index += 1;
          result.questions.push({
            value: line,
            index,
          });
        }

        if (line.startsWith("- ")) {
          result.answers.push({
            value: line,
            questionIndex: index,
          });
        }
      }

      console.log(result);
      loadedSyllabus.value = result;

      router.push("/exam");
    }

    return {
      syllabus,
      study,

      PRESET,
      loadPreset,
    };
  },
});
