
import { loadedSyllabus } from "@/state/syllabus.state";
import { defineComponent, ref } from "vue";
import { ExamQuestion } from "../models/exam-question.model";

import ExamQuestionComponent from "../components/ExamQuestion.vue";

export default defineComponent({
  components: { ExamQuestionComponent },
  setup() {
    const points = ref(0);

    function randomItem<T>(arr: T[]) {
      return arr[~~(arr.length * Math.random())];
    }

    function shuffle(array: Array<any>) {
      var currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    }

    function pickContent(): ExamQuestion | undefined {
      if (loadedSyllabus.value == undefined) return undefined;

      const question = randomItem(loadedSyllabus.value?.questions);

      const item1 = randomItem(
        loadedSyllabus.value.answers.filter(
          (a) => a.questionIndex != question.index
        )
      );

      const answers = [
        randomItem(
          loadedSyllabus.value.answers.filter(
            (a) => a.questionIndex == question.index
          )
        ),
        item1,
        randomItem(
          loadedSyllabus.value.answers.filter(
            (a) => a.questionIndex != question.index && a != item1
          )
        ),
      ];

      // shuffle all answers
      shuffle(answers);

      return {
        question,
        answers,
      };
    }

    const examQuestion = ref(pickContent());

    function evaluate(success: boolean) {
      console.log(success);

      if (success) {
        points.value++;
      } else {
        points.value = 0;
        alert("Wrong answer! Ouch...");
      }

      examQuestion.value = pickContent();
    }

    return {
      examQuestion,
      evaluate,

      points,
    };
  },
});
