import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Study from '../views/Study.vue'
import Exam from '../views/Exam.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Study',
    component: Study
  },
  {
    path: '/exam',
    name: 'Exam',
    component: Exam
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
