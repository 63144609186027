<template>
  <div>
    <h2>{{ question.question.value.substring(3) }}...</h2>

    <ul>
      <li v-for="(answer, i, k) of question.answers" :key="k">
        <button
          @click="
            $emit('answer', answer.questionIndex == question.question.index)
          "
        >
          {{ answer.value.substring(1) }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { ExamQuestion } from "@/models/exam-question.model";
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["answer"],
  props: {
    question: {
      type: Object as () => ExamQuestion,
      required: true,
    },
  },
});
</script>


<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul {
  display: grid;
  grid-auto-flow: row;
  row-gap: 8px;
  margin-top: 64px;
}

h2 {
  font-size: 1.6rem;
}

button {
  width: 100%;

  font-size: 1.2rem;
  padding: 14px 24px;
  border-radius: 4px;

  border: none;
}

div {
  height: 100%;
  margin: 0 12px 120px 12px;
}
</style>