export interface Preset {
    name: string,
    content: string
}

export const PRESET: Preset[] = [
    {
        name: 'Americká literatura 19.-20. stol - autoři',
        content: `
        ## F. S. Fitzgerald

- Něžná je noc
- Velký Gatsby

## J. Steinbeck

- O myších a lidech
- Hrozny hněvu
- Na východ od ráje

## T. Dreiser

- Americká tragedie

## S. Lewis

- Hlavní třída

## W. Faulkner

- Vesnice
- Město
- Pánské sídlo

## T. Williams

- Tramvaj do stanice touha
- **Skleněný zvěřinec** → opuštění jihu = opuštění rodiny
- Kočka na rozpálené plechové střeše

## E. O'neill

- Cesta dlouhým dnem do noci
- Smutek sluší elektře

## Ernest Hemingway

- Sbohem, armádo
- Komu zvoní hrana
- Stařec a moře
- nobelova cena
- ztracená generace, vyznamenám z 1.sv. války
- přestěhoval se do EU, narozen v Chicagu
`
    },
    {
        name: 'Ruská (sovětská) literatura 19.-20. stol - autoři',

        content: `
        ## Gorkij

- **Matka** - matka, smrt při jedné z jeho akcí
- **Moje univerzity** - autobiografie, ironie (byl chudý), životní zkušenost

---

## Šolochov

- **Tichý don** - nobelova cena, průběh 1. sv. války a revoluce z pohledu kozácké komunity na Donu
- **Osud člověka** - ruští váleční zajatci (tabu)

---

## Isaak Babel

- žid, měl být odvezen do gulagu, nakonec zastřelen
- převážně satira
- **Rudá Jízda** - o tažění rudé armád
- **Historie mého holubníku**
- **Oděské povídky**

---

## Ivan Bunin

- žil ve francii, narozen v Rusku

- **Míťova láska**
- **Život Arseněvův** - nobelova cena jako první rus

---

## Bulgakov

- postmoderna

- **Mistr a Markétka** - motivy z fausta, objeví se ďábel, Markétka uzavře smlouvu s ďáblem, využívá se i motivu Vlapuržiny noci - čarodějnického sabatu (motiv jako ve Faustovi)
- **Zápisky mladého lékaře**
- **Divadelní román** - herec Stanislavskij
- **Život pana Moliéra**

- **Psí srdce** - operace psího srdce do člověka, více pudu (ke státu), prezentuje se jako + i když není

- **Osudová vejce**
- **Smrt těla / smrt duše**

---

## J. Zamjatin

- **My** - antiutopie, průhledné budovy, povinné štestí, čísla místo jmen, omezen sex. styk`
    }
]